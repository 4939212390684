import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Form, Input, InputNumber } from "antd";
import {
  CRYPTOCURRENCY_FORM,
  CRYPTOCURRENCY_TO,
} from "../../constants/CryptocurrencyArray";
import { formattedNumber } from "../../utils/formattedNumber";
import { useTranslation } from "react-i18next";
import ModalCurrencyFrom from "../Modal/ModalCurrencyFrom";
import ModalCurrencyTo from "../Modal/ModalCurrencyTo";

function Exchange() {
  const { t } = useTranslation();
  const basePath = "exchange.";

  const getDefaultState = () => ({
    amount: 0.4,
    currencyTo: "LTC",
    currencyFrom: "BTC",
    infoCoinTo: CRYPTOCURRENCY_TO[1],
    infoCoinFrom: CRYPTOCURRENCY_FORM[0],
    email: "",
    wallet: "",
    exchangeRate: parseFloat(localStorage.getItem("exchangeRate")) || null,
    result: localStorage.getItem("exchangeResult") || "",
  });

  const [state, setState] = useState(() => {
    const storedState = localStorage.getItem("exchangeFormData");
    return storedState ? JSON.parse(storedState) : getDefaultState();
  });

  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [isRulesChecked, setIsRulesChecked] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isWalletValid, setIsWalletValid] = useState(true);
  const [modalToOpen, setModalToOpen] = useState(false);
  const [modalFromOpen, setModalFromOpen] = useState(false);

  const showModalTo = () => {
    setModalToOpen(true);
  };

  const showModalFrom = () => {
    setModalFromOpen(true);
  };

  const handleCancel = () => {
    setModalToOpen(false);
    setModalFromOpen(false);
  };

  const isFiatCurrency = (currency) => {
    const fiatCurrencies = ["USD", "EUR", "GBP", "AUD", "CAD"];
    const currencySymbol = currency.substring(0, 3).toUpperCase();
    return fiatCurrencies.includes(currencySymbol);
  };

  const getActualSymbol = (symbol) => {
    if (symbol.startsWith("USDT")) {
      return "USDT";
    }
    return symbol;
  };

  const fetchRate = async (currency) => {
    const actualCurrency = getActualSymbol(currency);
    const url = isFiatCurrency(actualCurrency)
      ? `https://api.exchangerate-api.com/v4/latest/${actualCurrency}`
      : `https://api.binance.com/api/v3/ticker/price?symbol=${actualCurrency}USDT`;

    return fetch(url);
  };

  const fetchExchangeRate = async () => {
    try {
      let fromCurrencyToUsdRate;
      let toCurrencyToUsdRate;

      const fromResponse = await fetchRate(state.currencyFrom);
      const fromCurrencyData = await fromResponse.json();
      fromCurrencyToUsdRate = isFiatCurrency(state.currencyFrom)
        ? fromCurrencyData.rates.USD
        : parseFloat(fromCurrencyData.price);

      const toResponse = await fetchRate(state.currencyTo);
      const toCurrencyData = await toResponse.json();
      toCurrencyToUsdRate = isFiatCurrency(state.currencyTo)
        ? toCurrencyData.rates.USD
        : parseFloat(toCurrencyData.price);

      if (!fromCurrencyToUsdRate || !toCurrencyToUsdRate) {
        throw new Error("Failed to fetch exchange rates");
      }

      const exchangeRate =
        (state.currencyFrom === "TRX") & (state.currencyTo === "USDT2")
          ? 2.7578
          : (state.currencyFrom === "USDT2") & (state.currencyTo === "TRX")
          ? 3.504
          : fromCurrencyToUsdRate / toCurrencyToUsdRate;

      // exchangeRate = fromCurrencyToUsdRate / toCurrencyToUsdRate;
      setState((prevState) => ({
        ...prevState,
        exchangeRate,
      }));
      localStorage.setItem("exchangeRate", exchangeRate);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setState((prevState) => ({
        ...prevState,
        exchangeRate: null,
      }));
      localStorage.removeItem("exchangeRate");
    }
  };

  const calculateExchange = () => {
    const { amount, exchangeRate } = state;

    if (isNaN(amount) || amount <= 0 || exchangeRate === null) {
      setState((prevState) => ({
        ...prevState,
        result: null,
      }));
      return;
    }

    const calculatedResult = (amount * exchangeRate).toFixed(8);
    setState((prevState) => ({
      ...prevState,
      result: calculatedResult,
    }));
    localStorage.setItem("exchangeResult", calculatedResult);
  };

  useEffect(() => {
    fetchExchangeRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currencyFrom, state.currencyTo]);

  useEffect(() => {
    calculateExchange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.amount, state.exchangeRate]);

  useEffect(() => {
    localStorage.setItem("exchangeFormData", JSON.stringify(state));
  }, [state]);

  const tooltipInnerRef1 = useRef(null);
  const tooltipInnerRef2 = useRef(null);
  const tooltipEmailRef = useRef(null);
  const tooltipWalletRef = useRef(null);

  const validateMessages = {
    required: "Введите сумму",
  };

  const handleCheckboxChange = (setter, ref) => (e) => {
    setter(e.target.checked);
    if (e.target.checked) {
      ref.current.classList.remove("active");
    }
  };

  const handleInputChange = (setter, ref, validator) => (e) => {
    const value = e.target.value;
    const isValid = validator(value);
    setter(isValid);
    if (isValid) {
      ref.current.classList.remove("active");
    }
    return value;
  };

  const emailValidator = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const walletValidator = (wallet) => {
    return wallet.length > 0;
  };

  const onFinish = () => {
    const isEmailValid = emailValidator(state.email);
    const isWalletValid = walletValidator(state.wallet);

    if (
      !isAgreementChecked ||
      !isRulesChecked ||
      !isEmailValid ||
      !isWalletValid
    ) {
      if (!isAgreementChecked) {
        tooltipInnerRef1.current.classList.add("active");
      } else {
        tooltipInnerRef1.current.classList.remove("active");
      }

      if (!isRulesChecked) {
        tooltipInnerRef2.current.classList.add("active");
      } else {
        tooltipInnerRef2.current.classList.remove("active");
      }

      if (!isEmailValid) {
        tooltipEmailRef.current.classList.add("active");
      } else {
        tooltipEmailRef.current.classList.remove("active");
      }

      if (!isWalletValid) {
        tooltipWalletRef.current.classList.add("active");
      } else {
        tooltipWalletRef.current.classList.remove("active");
      }

      return;
    }

    window.location.href = "/order/";
  };

  return (
    <div className="exchange">
      <section>
        <div className="exchange__block">
          <h2>
            {t(`${basePath}title.main`)} {state.infoCoinFrom.name}{" "}
            {t(`${basePath}title.and`)} {state.infoCoinTo.name}
            <span>
              <a href="/aml/">{t(`${basePath}aml`)}</a>
            </span>
          </h2>

          <Form validateMessages={validateMessages} onFinish={onFinish}>
            <div className="direct">
              <div className="columnInput">
                <label>{t(`${basePath}form.columnInput.title`)}</label>
                <div className="inputSelect">
                  <div className="leftInfo">
                    <button
                      type="button"
                      className="input"
                      onClick={showModalFrom}
                    >
                      <span className="icon">
                        <img src={state.infoCoinFrom.icon} alt="" />
                      </span>
                      <s>{state.infoCoinFrom.name}</s>
                      <span className="arrow"></span>
                    </button>
                  </div>

                  <div className="rightInfo">
                    {t(`${basePath}form.columnInput.right`)}
                  </div>
                </div>

                <div className="inputSelect">
                  <div className="leftInfo">
                    <div className="inputValue">
                      <span className="icon">
                        {getActualSymbol(state.infoCoinFrom.symbol)}
                      </span>
                      <InputNumber
                        className="m-input"
                        placeholder={0.4}
                        value={state.amount}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            amount: value,
                          }))
                        }
                        controls={false}
                      />
                    </div>
                  </div>

                  <div className="rightInfo"></div>
                </div>
              </div>
              <div className="columnOutput">
                <label>{t(`${basePath}form.columnOutput.title`)}</label>
                <div className="inputSelect">
                  <div className="leftInfo">
                    <button
                      type="button"
                      className="input"
                      onClick={showModalTo}
                    >
                      <span className="icon">
                        <img src={state.infoCoinTo.icon} alt="" />
                      </span>
                      <s>{state.infoCoinTo.name}</s>
                      <span className="arrow"></span>
                    </button>
                  </div>

                  <div className="rightInfo">
                    {t(`${basePath}form.columnOutput.right`)}{" "}
                    {state.infoCoinTo.reserve}
                    {getActualSymbol(state.infoCoinTo.symbol)}
                  </div>
                </div>

                <div className="inputSelect">
                  <div className="leftInfo">
                    <div className="inputValue">
                      <span className="icon">
                        {getActualSymbol(state.infoCoinTo.symbol)}
                      </span>
                      <InputNumber
                        className="m-input"
                        placeholder={1}
                        value={state.result}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="info">
                  {t(`${basePath}form.columnOutput.desc`)}
                </div>
              </div>
            </div>

            <div className="exchangeRate">
              {t(`${basePath}form.rate`)} 1{" "}
              {getActualSymbol(state.currencyFrom)} ={" "}
              {formattedNumber(state.exchangeRate)}{" "}
              {getActualSymbol(state.currencyTo)}
            </div>

            <div className="exchangeData">
              <div className="columnInput">
                <Form.Item name={"email"} type="email">
                  <Input
                    placeholder={t(`${basePath}form.email.label`)}
                    value={state.email}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        email: handleInputChange(
                          setIsEmailValid,
                          tooltipEmailRef,
                          emailValidator
                        )(e),
                      }))
                    }
                  />
                </Form.Item>
                <div className="tooltip-inner" ref={tooltipEmailRef}>
                  {t(`${basePath}form.email.error`)}
                </div>
              </div>
              <div className="columnOutput">
                <Form.Item name={"wallet"}>
                  <Input
                    placeholder={`${t(`${basePath}form.wallet.label`)} ${
                      state.infoCoinTo.name
                    }`}
                    value={state.wallet}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        wallet: handleInputChange(
                          setIsWalletValid,
                          tooltipWalletRef,
                          walletValidator
                        )(e),
                      }))
                    }
                  />
                </Form.Item>
                {state.infoCoinTo.memo && (
                  <Form.Item name={"memo"}>
                    <Input
                      placeholder={state.infoCoinTo.memo.placeholder}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          memo: e.target.value,
                        }))
                      }
                    />
                  </Form.Item>
                )}
                <div className="tooltip-inner" ref={tooltipWalletRef}>
                  {t(`${basePath}form.wallet.error`)} {state.infoCoinTo.name}.
                </div>
                <div className="captchaBlock">
                  <div className="captchaimg">
                    <div>
                      <img
                        src={require("../../assets/img/captcha/captcha1.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="captchaInput">
                    <Input placeholder={t(`${basePath}form.captcha`)} />
                  </div>
                </div>
              </div>
            </div>

            <div className="exchangeFooter">
              <div className="someText">
                <div>
                  <Form.Item name="agreement" valuePropName="checked">
                    <Checkbox
                      onChange={handleCheckboxChange(
                        setIsAgreementChecked,
                        tooltipInnerRef1
                      )}
                    >
                      {t(`${basePath}form.checkbox.first.title`)}{" "}
                      <a href="/rules/">
                        {t(`${basePath}form.checkbox.first.rules`)}
                      </a>
                    </Checkbox>
                    <div className="tooltip-inner" ref={tooltipInnerRef1}>
                      {t(`${basePath}form.checkbox.first.error`)}
                    </div>
                  </Form.Item>

                  <Form.Item name="rules" valuePropName="checked">
                    <Checkbox
                      onChange={handleCheckboxChange(
                        setIsRulesChecked,
                        tooltipInnerRef2
                      )}
                    >
                      {t(`${basePath}form.checkbox.second.title`)}{" "}
                      <a href="/aml/">
                        {t(`${basePath}form.checkbox.second.rules`)}
                      </a>
                    </Checkbox>
                    <div className="tooltip-inner" ref={tooltipInnerRef2}>
                      {t(`${basePath}form.checkbox.second.error`)}
                    </div>
                  </Form.Item>
                </div>
              </div>

              <div className="exchangeButton">
                <Button
                  htmlType="submit"
                  className="btn-primary"
                  type="primary"
                >
                  {t(`${basePath}form.btn`)}
                </Button>
              </div>
            </div>

            <div className="infoFooter">
              <p>{t(`${basePath}form.info.desc1`)}</p>
              <p>
                {t(`${basePath}form.info.desc2`)}{" "}
                <a href="/rules/">13.6, 13.7, 13.8, 13.9</a>
              </p>
            </div>

            <div className="updateCourse">
              {t(`${basePath}form.info.desc3`)}
            </div>
          </Form>
        </div>
      </section>

      <ModalCurrencyTo
        modalFromOpen={modalToOpen}
        handleCancel={handleCancel}
        setState={setState}
      />

      <ModalCurrencyFrom
        modalFromOpen={modalFromOpen}
        handleCancel={handleCancel}
        setState={setState}
      />
    </div>
  );
}

export default Exchange;
